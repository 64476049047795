import { loadRegions, loading } from "./location.api"
import { byTransl } from "../useLanguages";

const locationsColl = ref<any>([]);
const regionsColl = ref<any>(null);
const regionsCountry = computed(() => first(regionsColl.value)?.location_country)

export function useLocation() {

    async function fetchRegions(countryIso: string) {
        const locations: any[] = await loadRegions({ countryIso });
        locations.sort((a, b) => byTransl(a, b, p => p.location_path));
        regionsColl.value = locations;
    }

    return {
        loading,
        locationsColl,
        regionsColl,
        regionsCountry,
        fetchRegions
    }
}