import { useAxios } from "../useAxios";

const { axios, loading } = useAxios();

async function loadRegions({ countryIso }: any) {
    const params = {
        locationType: 0,
        countryIso
    }

    const { locations } = await axios.get('/locations', { params }) || {};
    return locations;
}

export { 
    loadRegions,
    loading
}