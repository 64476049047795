<script setup>
import { useLocation } from "@/composables/useLocation";
import { transl } from "@/composables/useTranslation";
import { useValidation } from "~/composables/useValidation";

const props = defineProps(["location", "rules", "label", "disabled", "countryIso"]);
const emit = defineEmits(["update:location", "update:disabled"]);
const attrs = useAttrs();

const { fetchRegions, regionsCountry, regionsColl } = useLocation();

const disabled = ref(false);
const location = ref(null);
const regionsDialog = ref(false);
const regionHtmlEl = ref(null);
const isEmpty = computed(() => !location.value.location_path);

watch(
  () => location.value,
  () => emit("update:location", location.value)
);
watchEffect(() => emit("update:disabled", disabled.value));

watchEffect(() => (disabled.value = props.disabled));
watchEffect(() => (location.value = props.location));

watch(
  () => [props.countryIso, disabled.value],
  () => tryFetchRegions(!disabled.value),
  { immediate: true, deep: true }
);

//load regions list when switch is enabled
async function tryFetchRegions() {
  if (props.countryIso && props.countryIso != regionsCountry.value) {
    await fetchRegions(props.countryIso);
  }
}

function openDialog() {
  regionHtmlEl.value?.focus();
  regionsDialog.value = true;
}

function handleOnBlur() {
  validate();
}

function handleOnChange() {
  validate();
}

// validation
const form = inject("form", null);
const validationModel = useValidation(
  computed(() => location.value.location_path),
  props.rules
);
const { hasErrors, errors, validate } = validationModel;

onMounted(() => {
  if (form) form?.register(validationModel);
});

onUnmounted(() => {
  if (form) form.unregister(validationModel);
});

// expose
defineExpose({
  hasErrors,
  errors,
  validate,
  focus,
  blur,
});
</script>
<template>
  <div
    class="v-region-field"
    :class="{ error: hasErrors }"
    :ref="(el) => (regionHtmlEl = el)"
    tabindex="0"
    @blur="handleOnBlur"
  >
    <v-label :class="{ active: !isEmpty, error: hasErrors }">{{ label }}</v-label>

    <v-btn :disabled="disabled" @click="openDialog()" class="button-el">
      <div v-if="location?.location_path">{{ location?.location_path }}</div>
      <v-svg
        src="/icons/chevron-down-svgrepo-com.svg"
        class="ml-auto mt-1"
        :options="{ width: '30px' }"
      ></v-svg>
    </v-btn>

    <v-error :class="{ active: hasErrors }" class="error-el">
      {{ transl(errors[0]) }}</v-error
    >

    <v-region-dialog
      v-model="regionsDialog"
      v-model:location="location"
      :items="regionsColl"
      @change="handleOnChange"
    ></v-region-dialog>
  </div>
</template>

<style scoped>
.v-region-field {
  box-sizing: border-box;
  position: relative;
  outline: 1px solid var(--forms-border, #b3b3b3);
}
.v-region-field.error {
  outline: 2px solid rgb(255, 104, 28) !important;
}

.v-region-field:not(.error):focus-within {
  outline: 2px solid var(--forms-border-focus, #0957d0);
}

.switch-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.button-el {
  /* font-size: 18px; */
  display: flex;
  justify-content: flex-start;
  background-color: var(--forms-bg);
  border-radius: 5px;
  border: 1.5px solid var(--forms-border);
  padding: 0px 20px;
  color: var(--text-color);
  height: 100%;
}

.error-el {
  margin-left: 20px;
}

/* .button-el :deep(svg path) {
  stroke: blue !important;
}
.button-el:hover :deep(svg path) {
  stroke: red !important;
} */

* {
  box-sizing: border-box;
}
</style>